.post-builder {
  width: 100%;
  > .header {
    margin-bottom: 30px;
    > .button {
      width: 180px;
      height: 48px;
      border: 1px solid rgb(243, 219, 199);
      background-color: rgb(243, 219, 199);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      &:hover {
        border: 1px solid rgb(246, 229, 213);
        background-color: rgb(246, 229, 213);
      }
      > .text {
        text-decoration: none;
        font-family: Hind;
        font-size: 18px;
        color: rgb(64, 64, 64);

      }
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1220px;
    margin: 0 auto;
  }
  .divider {
    border-left: 2px solid black;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  margin-left: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ecb697;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ecb697;
}