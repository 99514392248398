.MuiPickersToolbar-toolbar, .MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiPickersClockPointer-thumb  {
  background-color: #F3DBC7 !important;
}
.MuiPickersClockPointer-thumb {
  border: 14px solid #F3DBC7 !important;
}
.MuiPickersToolbarText-toolbarTxt {
  color: rgba(0, 0, 0, 0.9) !important;
  &.MuiPickersToolbarText-toolbarBtnSelected {
    color: #fff !important;
  }
}

.MuiPickerDTTabs-tabs {
  background-color: #F3DBC7 !important;
}

.MuiSvgIcon-root {
  color: black !important;
}

.single-submission {
	width: 100%;

	.single-submission-header-container {
		width: 100%;
	}

	.single-submission-body {
		display: flex;
		flex-direction: row;
		width: 1220px;
		justify-content: space-between;
		margin: 60px auto;

		.single-submission-body-container {
			width: 750px;
				
			.single-submission-general-info-container, .single-submission-additional-info-container {
				width: 720px;
			}

      .single-submission-additional-info-container {
        margin-top: 60px;
      }
		}

		.single-submission-sidebar {
			width: 440px;
      .post-preview-container {
        width: 430px;
        float: right;
        margin-top: 20px;
        .post-preview-button-container {
          width: 205px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(243, 219, 199);
          border-radius: 4px;
          cursor: pointer;
          .post-preview-button-text {
            font-size: 18px;
            text-align: center;
            font-family: Hind;
            letter-spacing: 0px;
            text-transform: none;
            color: rgb(64, 64, 64);
          }
        }
        .post-preview-buttons-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
	}
}

a {
	text-decoration: none;
}

.single-submission-requirements {
	margin-top: 30px;

	.requirement {
		font-family: 'Hind';
		font-size: 18px;
		color: #000000;
	}
}

.MuiList-root {
	max-height: 250px;
}

.single-submission-video-section {
	margin-bottom: 100px;
}

.MuiPickersDay-daySelected, .MuiButton-textPrimary {
	background-color: #F3DBC7 !important;
	color: #000000 !important;
}

.MuiPickersDay-current {
	color: #000000 !important;
}

.credit-field-container {
	width: 245px;
	display: inline-block;
}

.MuiChip-deleteIcon {
	border-radius: 50%;
}

.MuiButtonBase-root {
	z-index: 2 !important;
	max-height: 190px;
  overflow: auto;
  position: absolute;
  margin-left: 2px;
}

.MuiTouchRipple-root {
	display: none !important;
}

.MuiChip-root {
	background-color: #F9EFE6 !important;
	font-family: "Hind" !important;
	height: 29px !important;
}

.MuiIconButton-root:hover {
	background-color: initial;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #F3DBC7 !important;
 }

 .MuiSvgIcon-root {
 	fill: #FFFFFF;
 }

 .credit-field-error {
 	height: 18px;
 	color: #f44336;
 	margin-top: 3px;
 	text-align: right;
 	margin-right: 10px;
 }

 .credits-form {
 	display: flex;
 	flex-direction: row;
 	margin: 15px 0;
 	height: 46px;
 }

.MuiFormLabel-root.Mui-focused {
	color: #F3DBC7 !important;
}

.single-submission-images-denied {
	width: 100%;
}

.single-submission-credit {
	margin-bottom: 14px;
}

.single-submission-images-section {
	margin-top: 15px;
	width: 720px;
}

.single-submission-images-container {
	display: flex;
	flex-direction: row;
	width: 720px;
	flex-wrap: wrap;
  justify-content: space-between;
}

.MuiMenuItem-root {
	font-family: "Hind" !important;
	font-weight: 300 !important;
}

div[role=tooltip] {
	will-change: unset !important;

}

.mui-ripple-circle {display: none!important}

.MuiPaper-root {
	margin: 5px 0 0 1px;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
	background-color: #F3DBC7 !important;
}

.single-submission-credit-text-container {
	width: 750px;
	display: inline-block;
}

button {
	cursor: pointer;
}

.pac-container {
	margin-top: -15px;
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	border-radius: 3px;
}

.pac-icon {
	display: none;
}

.pac-matched, .pac-item-query, .pac-item {
	font-family: 'Hind';
	font-size: 16px;
	font-weight: 300;
	color: #000;
}

.pac-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: none;
	height: 50px;
	padding-left: 13px;

	&:hover {
		background-color: #EEE;
	}
}